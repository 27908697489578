<template>
  <div class="shop">
    <left-tabs :tabList="leftList" />
    <router-view></router-view>
  </div>
</template>

<script>
import LeftTabs from "@/components/leftTabs";
export default {
  components: { LeftTabs },
  name: "comment",
  data() {
    return {
      leftList: [
        { id: 1, name: "评论列表", url: '/get_comment_list' },
        // { id: 1, name: "角色", url: '/jurisdiction/role' },
      ],
    };
  },
  methods: {},
  mounted(){
	  this.$router.push('/get_comment_list')
  },
};
</script>

<style scoped lang='less'>
.shop {
  display: flex;
}
</style>